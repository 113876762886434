const resources = [
  {
    page: {
      name: 'home',
      parts: [
        {
          partName: 'start page',
          title: 'Transform your Employee Well-being and Productivity with BI and Science',
          description: 'Save Thousands by Increasing your Employee Engagement rate and Retention rate with our HR BI Dashboard and Employee Mental Health App',
          buttonTitle: 'Contact sales',
          backgroundImage: require('../public/home/start-page-dashboard-1.png'),
          backgroundSecondImage: require('../public/home/start-page-dashboard-2.png'),
          backgroundMobileImage: require('../public/home/relaxify-background-image-mobile.svg')
        },


        {
          partName: 'HR SOLUTIONS DESIGNED TO HELP YOU',
          desktopLabel: 'HR SOLUTIONS DESIGNED TO HELP YOU',
          mobileLabel: 'HR SOLUTIONS TO HELP YOU',
          elements: [
            {
              image: require('../public/home/hr-solutions/employee-survey.svg'),
              title: 'HR BI Dashboard',
              description: 'Track the impact of HR initiatives and improve decision-making with data-driven analytics of insights and trends.'
            },
            {
              image: require('../public/home/hr-solutions/health-app.svg'),
              title: 'Employee Survey',
              description: 'Be aware of employee motivation, productivity, stress levels, burnout indicators, and overall wellness.'
            },
            {
              image: require('../public/home/hr-solutions/hr-consultancy.svg'),
              title: 'Tailored HR Consultancy ',
              description: 'Improve the results with an 1 year program with our Organizational Psychologists.'
            },
            {
              image: require('../public/home/hr-solutions/hr-dashboard.svg'),
              title: 'Employee Mental Health App ',
              description: 'Took the best of traditional and e-psychology and give access to your employees to health and well-being app available to interact anytime, anywhere.\n'
            }
          ],
          buttonTitle: 'Contact sales',
        },
        {
          partName: 'BENEFITS',
          title: 'By investing in Business Intelligence (BI) and employee mental health programs',
          yellowCircle: require('../public/corporate/success/yellow-dot.svg'),
          benefitsElements: [
            {
              image: require('../public/home/benefits/business-owners.svg'),
              title: 'Business Owners/Managers',
              subElements: [
                "Enhance Decision Making", 'Save Thousands of Money', 'Improve Employer Branding'
              ]
            },
            {
              image: require('../public/home/benefits/hr-managers.svg'),
              title: 'HR Managers',
              subElements: [
                "Improve Employee Retention", "Increase Employee Engagement", "Helps you comply with CSRD"
              ]
            },
            {
              image: require('../public/home/benefits/employees.svg'),
              title: 'Employees',
              subElements: [
                "Increase Job Satisfaction", 'Fosters Sense of Community and Support', 'Experience improved work-life balance and lower stress levels'
              ]
            },
          ],
          secondPart: [
            {
              title: "<b>Save Money</b> and Keep your best people",
              desc: "Do you know your <b>cost of employee turnover</b>?<br>Replacing an individual employee can <b>range from <br> </b> one-half to two times the employee's annual salary.</b> (Gallup)",
              elements: [
                '<b> We can increase your retention rate with 10-20 % and save you thousands of money! </b>'
              ]
            },
            {
              title: "Increase <b>Employee Engagement</b> and boost Productivity",
              desc: "<b> Engaged employees: </b>",
              elements: [
                "<b> increase productivity by 14% </b>",
                "<b> boost customer ratings by 10% </b>",
                "<b> and improve sales by 18%.</b> (Gallup)"
              ]
            }
          ]
        },
        {
          partName: 'ROADMAP',
          elements: ['Employee Survey', 'Insights and Strategy', 'HR BI Dashboard with seamless Integration', 'Well-being Program implementation']
        },
        {
          partName: 'HR BI Dashboard:',
          logoImage: require('../public/home/hr-bi-dashboard/hr-bi-dashboard-title-image.svg'),
          desc: 'Focus the Power of Business Intelligence with Power BI and our specialized IT and organizational psychology support',
          secondDesc: 'Power BI seamlessly integrates both <b>historical and new data from variety of sources,</b> providing a comprehensive view of your workforce metrics.',
          mobileImage: require('../public/home/hr-bi-dashboard/hr-bi-dashboard-mobile-image.png'),
          desktopImage: require('../public/home/hr-bi-dashboard/desktop-image.png'),
          mobileSecondImage: require('../public/home/hr-bi-dashboard/Dashboard with suggestions included 1 1.png'),
          desktopSecondImage: require('../public/home/hr-bi-dashboard/desktop-second-image.png'),
          elements: [
            'By adding our Employee Survey data, this powerful tool <b>enables continuous monitoring and in-depth analysis</b> of employee engagement and mental health indicators such as stress and burnout.',
            'With this <b>visibility</b> and the <b>tailored strategy and metrics</b> selected by our organizational psychologists, HR professionals are empowered to make informed, data-driven decisions that enhance the workplace environment.'
          ],
            dataSourceElements: [
            {
              image: require('../public/home/hr-bi-dashboard/data-source-logo.svg'),
              alt: 'logo'
            },
            {
              image: require('../public/home/hr-bi-dashboard/excel-software-logo 1.svg'),
              alt: 'excel'
            },
            {
              image: require('../public/home/hr-bi-dashboard/Microsoft_Access_2013-2019_logo.svg 1.png'),
              alt: 'access-logo'
            },
            {
              image: require('../public/home/hr-bi-dashboard/Microsoft_logo_(2012).svg 1.svg'),
              alt: 'microsoft'
            }
          ],
          buttonTitle: 'Request from Here'
        },
        {
          partName: 'Employee Survey',
          logoImage: require('../public/home/employee-survey/employee-survey-title-image.svg'),
          desc: 'BUSINESS METRICS:',
          secondDesc: 'MENTAL HEALTH METRICS:',
          businessMetricsElements: [
            {
              title: 'Employee Engagement',
              desc: 'Discover what motivates your employees and strengthens their commitment',
              image: require('../public/home/employee-survey/employee-engagemenet.svg'),
            },
            {
              title: 'Employee Productivity',
              desc: 'Analyze highlighted productivity barriers, helping you identify and address areas for improvement.',
              image: require('../public/home/employee-survey/employee-prodoctivity.svg'),
            },
            {
              title: 'Job Satisfaction',
              desc: 'Measure your employees overall satisfaction with their roles and relationships at work.',
              image: require('../public/home/employee-survey/Smile.svg'),
            }
          ],
          mentalHealthMetricsElements: [
            {
              title: 'Stress Levels',
              desc: 'Understand the frequency and intensity of stress your employees experience.  \n',
              image: require('../public/home/employee-survey/stress-level.svg'),
            },
            {
              title: 'Burnout Indicators',
              desc: 'Monitor and track signs of burnout to prevent it from impacting your team’s performance and well-being.',
              image: require('../public/home/employee-survey/burnout.svg'),
            },
            {
              title: 'Employee Wellness ',
              desc: 'Assess emotional, cognitive, physical, and social well-being and show your employees that their mental health matters.',
              image: require('../public/home/employee-survey/employee-wellness.svg'),
            }
          ],
          buttonTitle: 'Request from Here'
        },
        {
          partName: 'Human Resources Consultancy',
          desktopPartName: 'Tailored HR Consultancy',
          logoImage: require('../public/home/human-resources/human-resources-logo.svg'),
          desc: 'Our service is powered by the expertise of our organizational psychologists and HR experts, who are professionals in workplace well-being programs. With a deep understanding of organizational psychology, they have developed our comprehensive surveys.',
          secondDesc: 'When evaluating your survey, our experts craft a tailored strategy that includes corrective actions and engagement activities.',
          thirdDesc: 'Their <b>goal</b> is to:',
          mainImage: require('../public/home/human-resources/human-resorces-main-image.svg'),
          mainDesktopImage: require('../public/home/human-resources/human-resources-main-desktop-image.svg'),
          elements: [
            'help your organization thrive', 'boost employee retention', 'enhance satisfaction', 'improve productivity', 'while minimizing stress and preventing burnout'
          ],
          buttonTitle: 'Learn more at About us',
        },

        {
          partName: 'RelaxifyApp for your employees',
          logoImage: require('../public/home/relaxify-for-employee/relaxify-for-employee-logo.svg'),
          firstPhones: require('../public/home/relaxify-for-employee/first-phones.svg'),
          secondPhones: require('../public/home/relaxify-for-employee/second-phones.svg'),
          thirdPhones: require('../public/home/relaxify-for-employee/third-phones.svg'),
          desc: 'Our mental well-being app offers a wide range of resources, including stress management tools, mindfulness exercises all designed to support employees mental health.',
          buttonTitle: 'Learn more at About the app',
          secondButtonTitle: 'Ask for a trial period',
          purpleElements: {
            title: 'Employees achieve a variety of benefits:',
            desc: 'Manage Stress',
            elements: [
              'Prevent Anxiety and Burnout', 'Improve Coping Mechanisms', 'Boost Productivity', 'Train Focus and Attention', 'Education on Mental Health topics'
            ],
            googlePlayLogo: require('../public/icons/google-play.svg'),
            appStoreLogo: require('../public/icons/app-store.svg'),
            relaxifyLogo: require('../public/home/relaxify-for-employee/relaxify_-_logo 1.svg'),
            bgAssocicationForPeopleManagementLogo: require('../public/home/relaxify-for-employee/bulgarian-association-for-people-management.svg'),
            bescoLogo: require('../public/home/relaxify-for-employee/besco-logo-clean 1.svg'),
            euStartupLogo: require('../public/home/relaxify-for-employee/EU-Startups-Logo 1.svg'),
            forbesLogo: require('../public/home/media/forbes.svg'),
            capitalLogo: require('../public/home/media/kapital.svg'),
            bloombergLogo: require('../public/home/media/bloomberg.svg'),
            winnerLogo: require('../public/home/relaxify-for-employee/winner-logo.svg'),
            memberOf: 'MEMBER OF:',
            inMedia: 'IN MEDIA',
            awards: 'AWARDS',
          }
        },
        {
          partName: 'last part',
          title: 'Take action! Nurture Your People and Drive Business Success',
          elements: [
            'Happier, healthier employees work more efficiently',
            'Enhanced well-being leads to higher retention rates',
            'A positive environment fosters greater employee satisfaction',
            'A commitment to well-being attracts top talent'
          ],
          image: require('../public/home/relaxify-for-employee/last-part-image.svg'),
        },
        {
          partName: 'THE PUZZLE',
          title: 'Empower Yourself',
          description: 'Working on your physical, emotional, cognitive and social well-being is how you pick up the pieces and put your puzzle together. By completing the various exercises of your in-app program, you will be able to have a positive impact on:',
          image: require('../public/home/puzzle/phone-puzzle.png'),
          image_desktop: require('../public/home/puzzle/puzzle_desktop.png')
        },
        {
          partName: 'METHODOLOGY',
          title: 'The Health and Well-Being App, Inspired by Innovation',
          description: 'In RelaxifyApp, we’ve combined the latest findings in the world of conventional psychology and e-psychology with the most advanced mobile technologies. ',
          description_desktop: 'In RelaxifyApp, we’ve combined the latest findings in the world of conventional psychology and e-psychology with the most advanced mobile technologies. \n' +
            'Designed by professional psychologists and psychotherapists, our app aims to help you build positive mental health hygiene and increase your well-being capacities to prevent conditions such as burnout, anxiety, depression., etc.',
          image: require('../public/home/methodology/methodology.png'),
          buttonTitle: 'Learn more'
        },
        {
          partName: 'USER TESTIMONIALS',
          title: 'People love RelaxifyApp',
          description: 'Our app has received rave reviews from those who have already begun their well-being journey - read on to see what they have to say.',
          userReviews: [
            {
              description: 'Easy to follow and super well-structured program, tailored down to my own well-being state. Every week, different exercises are offered to address various problems, and it all comes as fun and engaging as one would expect. Love it!',
              image: require('../public/home/user-reviews/maria.png'),
              name: 'Maria D'
            },
            {
              description: 'Relaxify helped me understand my own needs better and got me working on my weaknesses every day. It\'s the next best thing after visiting an actual therapist, and I can say it as someone who sees a therapist in real life. Well done, guys!',
              image: require('../public/home/user-reviews/saoirse.png'),
              name: 'Saoirse J.'
            },
            {
              description: 'Fun and useful! I love the weekly tasks and the way Relaxify makes you compete with yourself for your own sake. It\'s a game you can\'t lose!',
              image: require('../public/home/user-reviews/irene.png'),
              name: 'Irene B.'
            },
            {
              description: 'I really like the training program, it gives me every day the exercise that I have to do it. So far I have reach the first goal, and I feel good!',
              image: require('../public/home/user-reviews/goran.png'),
              name: 'Goran B.'
            },
            {
              description: 'I rarely stick to an app for more than three days in a row, but that one is somewhat hard to leave behind. It’s fun, easy to use, and actually beneficial for my overall awareness of what happens in my mind. I would recommend it 100%.',
              image: require('../public/home/user-reviews/nikola.png'),
              name: 'Nikola K.'
            },
            // {
            //     description: 'I felt something was wrong; I just couldn’t name what was wrong exactly. <b>Relaxify was quite a revelation</b>, to be honest - defining my weaknesses and helping me work on them daily. It’s a game-changer!',
            //     image: require('../public/home/user-reviews/avatar.svg')
            // },
            // {
            //     description: 'I felt something was wrong; I just couldn’t name what was wrong exactly. <b>Relaxify was quite a revelation</b>, to be honest - defining my weaknesses and helping me work on them daily. It’s a game-changer!',
            //     image: require('../public/home/user-reviews/avatar.svg')
            // }
          ],
          quotesAsImage: require('../public/home/user-reviews/quotes.png')
        },
        {
          partName: 'NUMBERS',
          title: 'And Let Numbers Paint the Picture',
          description: 'We believe that measurable results speak louder than words, so here is what RelaxifyApp has managed to achieve so far.',
          items: [
            {
              description: 'users trusted the RelaxifyApp in one year',
              number: '25,000',
              image: require('../public/home/numbers/users.svg'),
              altTag: 'users downloading an app'
              ,
            },
            {
              description: 'survey analyses were completed in one year',
              number: '95,000',
              image: require('../public/home/numbers/survey.svg'),
              altTag: 'well-being surveys'
            },
            {
              description: 'improved their well-being after six weeks',
              number: '55%',
              image: require('../public/home/numbers/well-being.svg'),
              altTag: 'improve your well-being'
            },
            {
              description: 'stars average rating on the Apple App and Play Stores',
              number: '4.4',
              image: require('../public/home/numbers/average-rating.svg'),
              altTag: 'reviews of RelaxifyApp'
            }
          ]
        },
        {
          partName: 'BE HAPPY',
          title: 'Get Free Stress Release and Well-Being Tips',
          description: 'Keep an eye on our blog, where you’ll find tons of useful and hands-on advice on how to improve your mental health in the long run.',
          items: [
            {
              title: 'High Expectations vs. High Standards in a Relationship',
              description: 'Building and maintaining healthy relationships with others is not an exact science, and you can’t use a single formula to get you safely through your social realm.',
              background_image: require('../public/home/articles/high-expectations.png'),
              redirectUrl: 'https://relaxifyapp.com/high-expectations-vs-high-standards/'
            },
          ],
          nextArticles: [
            {
              title: 'How to Stop an Anxiety Attack',
              description: 'If you\'ve been battling fear, nervous tension, and panic attack episodes, you\'re far from alone in fighting this battle.',
              redirectUrl: 'https://relaxifyapp.com/how-to-stop-an-anxiety-attack/',
            },
            {
              title: 'The Most Exciting Trends In Positive Psychology Interventions',
              description: 'Aside from promoting healthy eating habits, positive psychology has also been integrated into educational interventions to support youth.',
              redirectUrl: 'https://relaxifyapp.com/the-most-exciting-trends-in-positive-psychology-interventions/',
            }
          ]
        },
        {
          partName: 'IN MEDIA',
          title: 'As Seen In',
          mediaLogos: [
            {
              image: require('../public/home/media/forbes.svg'),
              alt: 'forbes',
            },
            {
              image: require('../public/home/media/kapital.svg'),
              alt: 'kapital'
            },
            {
              image: require('../public/home/media/bloomberg.svg'),
              alt: 'bloomberg',
            },
          ]
        },
        {
          partName: 'COLLABORATION',
          title: 'Our Partners',
          collaborationLogos: [
            {
              image: require('../public/home/media/myworld.svg'),
              alt: 'myworld',
            },
            {
              image: require('../public/home/media/rebenefit.svg'),
              alt: 'rebenefit'
            }
          ]
        },
      ]
    }
  },
  {
    page: {
      name: 'about-the-app',
      parts: [
        {
          partName: 'start page',
          title: 'A Science-Based Mental Training App to Use on the Go',
          desktop_description_first_part: 'RelaxifyApp is all about empowering you to take control of your mental health. We have created a health and well-being app that combines the best of traditional psychology and e-psychology, while being accessible anytime and anywhere.',
          desktop_description_second_part: 'Our mental health app is based on science, supported by data, and created by certified psychologists. We prioritize both the effectiveness and enjoyment of our app, as we take mental health care seriously.',
          mobile_description: 'RelaxifyApp is all about empowering you to take control of your mental health. We have created a health and well-being app that combines the best of traditional psychology and e-psychology, while being accessible anytime and anywhere.',
          buttonTitle: 'Contact sales'
        },
        {
          partName: 'METHODOLOGY',
          title: 'The Journey Towards Yourself',
          description: 'We have created an easy-to-follow experience to help you reduce stress, manage anxiety, and avoid burnout. It is designed to positively improve your resilience, mental hygiene, self-acceptance, coping skills, and other important areas. The journey includes the following steps:',
          backgroundImageDesktop: require('../public/about-the-app/methodology/methodology_background_desktop.png'),
          methodologies: [
            {
              title: 'The Well-Being Survey',
              path: require('../public/methodology/the-well-being-survey.png'),
              description: 'Start with a professional psychological questionnaire that will assess your current well-being state. It takes no longer than 10 minutes and consists of 25 carefully picked questions.'
            },
            {
              title: 'The Results',
              path: require('../public/methodology/the-evaluation.png'),
              description: 'Your survey answers form your well-being score on a 1 to 1000 scale in four different well-being dimensions: cognitive, social, emotional, and physical. The app provides you with additional information to help you better understand your score.'
            },
            {
              title: 'The Program',
              path: require('../public/methodology/the-program.png'),
              description: 'Your well-being score allows the app to automatically provide you with a daily mental health program tailored to your needs! It contains games and exercises, designed to improve your mental health capacities.'
            },
            {
              title: 'The Progress',
              path: require('../public/methodology/the-progress.png'),
              description: 'Follow your journey in the well-being tracker, which includes daily quests and weekly and monthly goals. As you work towards feeling better, you can earn points and badges to show off your progress. The exercises will focus on building resilience and a positive mindset while improving your weaknesses and development points.'
            },
          ]
        },
        {
          partName: 'THE SCIENCE',
          title: 'In Our Mental Training App, It’s All Based On Science',
          description: 'RelaxifyApp is a project conceived by professional psychologists and psychotherapists, certified to provide assistance for the prevention and treatment of mental health issues. It helps with self-development, increasing your QoL, overcoming impaired mental conditions and/or in parallel with professional psychotherapy but it should not be used as a replacement for one. All the in-app games and exercises are backed by science and supported by evidence, that you can find links to on every step of the way!',
          secondDescription: 'The elements in each individual well-being program are:',
          wellBeingElements: [
            {
              name: 'Cognitive-Behavioral Exercises',
              imagePath: require('../public/methodology/cognitive-exercise.svg')
            },
            {
              name: 'Gestalt Practices',
              imagePath: require('../public/methodology/gestalt-practices.svg')
            },
            {
              name: 'Mindfulness-Based Techniques',
              imagePath: require('../public/methodology/mindfulness-interventions.svg')
            },
            {
              name: 'Various Well-Being Surveys',
              imagePath: require('../public/methodology/repetitive-surveys.svg')
            },
            {
              name: 'Gamification Principles',
              imagePath: require('../public/methodology/gamification-techniques.svg')
            },
            {
              name: 'E-Psychology Interactiveness',
              imagePath: require('../public/methodology/e-interactiveness.svg')
            }
          ],
          buttonTitle: 'Contact sales'
        },
        {
          partName: 'GAMIFICATION',
          title: 'We Design a Fun and Stress-Free Way to Address Your Hardships',
          description: 'Developing new techniques for self-improvement feels like the best way to improve mental health, doesn’t it? We’ve made healing feel great and entertaining!',
          image: require('../public/gamification/gamification.png'),
          elements: [
            {
              title: 'Engagement',
              description: 'Multiple recent studies show that gamification improves the motivation, engagement, consistency, and results in the psychology healthcare niche.'
            },
            {
              title: 'Long-term Habits',
              description: 'Well-being games are especially beneficial in the process of behavioral shifts and building long-term habits for quality self-care.'
            },
            {
              title: 'Accessibility',
              description: 'Easily access RelaxifyApp anytime and anywhere with just a tap on your device. Play mental fitness games on the go to improve your well-being.'
            },
            {
              title: 'Innovation',
              description: 'Combining professional expertise with innovative technology, we chose gamification as the best approach for designing a product that can make a difference.'
            }
          ],
          circle_image: require('../public/gamification/gamification-circle.svg'),
          buttonTitle: 'Contact sales'
        },
        {
          partName: 'first-box',
          title: 'A Science-Based Mental Training App to Use on the Go',
          desktop_first_description: 'RelaxifyApp is all about empowering you to take control of your mental health. We have created a health and well-being app that combines the best of traditional psychology and e-psychology, while being accessible anytime and anywhere.',
          desktop_second_description: ''
        },
      ]
    }
  },
  {
    page: {
      name: 'about-us',
      parts: [
        {
          partName: 'first-box',
          title: 'We Want to Make Your World a Better Place',
          description: 'RelaxifyApp was born from our sincere desire to reduce the skyrocketing levels of depression, anxiety, post-traumatic stress and burnout worldwide.',
          secondDescription: 'We wanted to make it simple, easy to use, and deeply personal to meet your needs!',
          image: require('../public/about-us/start-background.svg')
        },
        {
          partName: 'OUR DRIVE',
          title: 'Our Mission and Vision',
          description: 'Our main goal is to make mental healthcare accessible and easy to incorporate into your daily routine',
          secondDescriptionFirstPart: 'Our desire to help people achieve better emotional, social, cognitive, and physical performance led us to develop RelaxifyApp.',
          secondDescriptionSecondPart: 'We’ve spent an extended period of time refining our vision and looking for the best ways to combine psychology and technology with the purpose of designing a future-proof way to carry your well-being plan in your pocket.',
          secondDescriptionThirdPart: 'The gamification element, allowed us to turn the experience into a fun and exciting way to build healthy habits.',
          buttonTitle: 'Contact sales'
        },
        {
          partName: 'TOGETHER',
          title: 'Our Values',
          description: 'We are bound together by shared understandings, priorities and values.',
          elements: [
            {
              title: 'Integrity',
              description: 'Work ethics, dependability, and steady moral compass are the starting points of every endeavor we undertake.',
              image: require('../public/about-us/together/integrity.svg')
            },
            {
              title: 'Commitment',
              description: 'Our shared feelings of purpose and meaning help us remain enthusiastic and stick to our long-term goals.',
              image: require('../public/about-us/together/commitment.svg')
            },
            {
              title: 'Respect ',
              description: 'We value everyone’s qualities and talents, appreciate everyone’s opinions, and protect everyone’s dignity.',
              image: require('../public/about-us/together/respect.svg')
            },
            {
              title: 'Innovation',
              description: 'We believe in future-proof solutions based on creativity, combinative thinking, and breakthrough technologies.',
              image: require('../public/about-us/together/innovation.svg')
            },
            {
              title: 'Responsibility',
              description: 'We rely on solid scientific proof and carefully tested assumptions so we can deliver a 100% reliable outcome.',
              image: require('../public/about-us/together/responsibility.svg')
            },
            {
              title: 'Expertise',
              description: 'Our team is committed to the process of ongoing competence upgrades with the latest findings in the niche.',
              image: require('../public/about-us/together/responsibility.svg')
            }
          ],
        },
        {
          partName: 'PROBLEM-SOLVERS',
          title: 'Meet Our Dream Team',
          description: 'Our core values, professional expertise, and hard work click together to design not merely a product, but a solution. Because we are here to solve problems, and so is the RelaxifyApp!',
          foundingTeam: {
            title: 'Founding Team',
            icon: require('../public/about-us/founding-team/icon.svg'),
            members: [
              {
                name: 'Aleksandar Stankov',
                role: 'CEO & Co-Founder',
                description: 'Alex is a serial entrepreneur with a passion for technology. He has demonstrated a proven track of building and managing top talent and teams, mostly in the field of master IT Project management.',
                image_mobile: require('../public/about-us/founding-team/Aleksandar-Stankov-mobile.png'),
                image_desktop: require('../public/about-us/founding-team/Aleksandar-Stankov-desktop.png')
              },
              {
                name: 'Yoan Petrov',
                role: 'CTO & Co-Founder',
                description: 'Yoan is an ex-corporate IT strategy consultant on a mission to apply the best corporate principles in the start-up world. Moreover - he is a perfectionist and a creative problem solver with an eye for details.',
                image_mobile: require('../public/about-us/founding-team/Yoan-Petrov-mobile.png'),
                image_desktop: require('../public/about-us/founding-team/Yoan-Petrov-desktop.png')
              },
              {
                name: 'Tome Nedinkovski ',
                role: 'Psychologist & Co-Founder',
                description: 'Tome is a psychologist and a Gestalt psychotherapist experienced in organizational psychology and implementing workplace well-being programs that enhance human performance, resilience, and mental health.',
                image_mobile: require('../public/about-us/founding-team/Tome-Nedinkovski-mobile.png'),
                image_desktop: require('../public/about-us/founding-team/Tome-Nedinkovski-desktop.png')
              }
            ],
          },
          psychologyTeam: {
            title: 'Psychology Team',
            icon: require('../public/about-us/psychology-team/icon.svg'),
            members: [
              {
                name: 'Marija Taneska',
                role: 'Psychologist',
                description: 'Marija is a bachelor of psychology with specialized interest in cognitive functioning, psychology of aging, psychological assessment and smart implementation of research-based well-being practices.',
                image: require('../public/about-us/psychology-team/Marija-Taneska.png'),
                image_desktop: require('../public/about-us/psychology-team/Marija-Taneska-desktop.png')
              },
              {
                name: 'Frosina Stojkoski',
                role: 'Psychologist',
                description: 'Frosina is a psychologist and Gestalt psychotherapist with neuropsychological specialization. She integrates her knowledge of neuropsychology and counseling skills into optimizing tour in-app exercises.',
                image: require('../public/about-us/psychology-team/Frosina-Stojkoski.png'),
                image_desktop: require('../public/about-us/psychology-team/Frosina-Stojkoski-desktop.png')
              },
              {
                name: 'Milena Pashoska ',
                role: 'Psychologist',
                description: 'Milena is a Master of Science in child and adolescent psychology and a Gestalt psychotherapist. She has skills in counseling, psychological assessment and working with young people.',
                image: require('../public/about-us/psychology-team/Milena-Pashoska.png'),
                image_desktop: require('../public/about-us/psychology-team/Milena-Pashoska-desktop.png')
              }
            ],
          }
        },
        {
          partName: 'GET IN TOUCH',
          title: 'Want to learn more about RelaxifyApp?',
          description: 'Get a demonstration of our capabilities, and find out how we can partner up.',
          form: {
            firstName: 'First name*',
            lastName: 'Last name*',
            email: 'Email*',
            message: 'Message*'
          },
          submitButtonTitle: 'Submit'
        },
      ]
    }
  },
  {
    page: 'header',
    menuOptions: [
      {title: 'HR Solutions', items: [], href: '/'},
      {title: 'RelaxifyApp for employees', href: 'https://relaxifyapp.com/business/', items: []},
      {title: 'About the App', href: '/about-the-app/', items: []},
      {title: 'Work-Life Balance Awards (BG)', href: '/awards/', items: []},
      {title: 'Blog', href: 'https://relaxifyapp.com/blog/', items: []},
      {title: 'About us', href: '/about-us/'},
      {title: 'Investors', href: '/corporate/', items: []},
    ],
    menuOptionsForCorporate: [
      {title: 'HR Solutions', items: [], href: '/'},
      {title: 'RelaxifyApp for employees', href: 'https://relaxifyapp.com/business/', items: []},
      {title: 'About the App', href: '/about-the-app/', items: []},
      {title: 'Work-Life Balance Awards (BG)', href: '/awards/', items: []},
      {title: 'Blog', href: 'https://relaxifyapp.com/blog/', items: []},
      {title: 'About us', href: '/about-us/'},
      {title: 'Investors', href: '/corporate/', items: []},
    ],
    menuOptionsForAwards: [
      {title: 'HR Solutions', items: [], href: '/'},
      {title: 'RelaxifyApp for employees', href: 'https://relaxifyapp.com/business/', items: []},
      {title: 'About the App', href: '/about-the-app/', items: []},
      {title: 'Work-Life Balance Awards (BG)', href: '/awards/', items: []},
      {title: 'Blog', href: 'https://relaxifyapp.com/blog/', items: []},
      {title: 'About us', href: '/about-us/'},
      {title: 'Investors', href: '/corporate/', items: []},
    ],
    logo: require('../public/header/relaxify-logo.svg')
  },
  {
    page: 'footer',
    upperTitle: 'START YOUR JOURNEY',
    corporateUpperTitle: 'REACH OUT',
    mainTitle: 'Are You Ready to Take Action?',
    corporateMainTitle: 'Invest in What Matters',
    description: 'Experience the difference that BI and personalized consultations can make in elevating your workplace well-being and productivity.',
    corporateDescription: 'If you want to invest in the things that matter, do not hesitate to reach out to us and have a chat about our partnership and investor programs.',
    corporateButtonTitle: 'Contact the Sales',
    talkToUs: 'Talk to us, we are here to help',
    googlePlayLogo: require('../public/icons/google-play.svg'),
    appStoreLogo: require('../public/icons/app-store.svg'),
    flowerLogo: require('../public/icons/flower-footer.svg'),
    yellowCircleImage: require('../public/footer/yellow-circle.svg'),
    blueCircleImage: require('../public/footer/blue-dot.svg'),
    redirectOptions: [
      {title: 'HR Solutions', items: [], href: '/'},
      {title: 'Blog', href: 'https://relaxifyapp.com/blog/', items: []},
      {title: 'RelaxifyApp for employees', href: 'https://relaxifyapp.com/business/', items: []},
      {title: 'About us', href: '/about-us/'},
      {title: 'About the App', href: '/about-the-app/', items: []},
      {title: 'Investors', href: '/corporate/', items: []},
      {title: 'Work-Life Balance Awards (BG)', href: '/awards/', items: []},
    ],
    terms: [
      {title: 'Relaxify Ltd. All rights reserved', href: '/'},
      {title: 'Privacy Policy', href: 'https://relaxifyapp.com/privacypolicy.html'},
      {title: 'Terms and Conditions', href: 'https://relaxifyapp.com/toc.html'},
    ],
    socialIcons: [
      {
        title: 'Facebook',
        path: require('../public/icons/social/facebook.svg'),
        href: 'https://www.facebook.com/relaxifyap/'
      },
      {
        title: 'LinkedIn',
        path: require('../public/icons/social/linkedin.svg'),
        href: 'https://www.linkedin.com/company/relaxifyapp'
      },
      {
        title: 'Instagram',
        path: require('../public/icons/social/instagram.svg'),
        href: 'https://www.instagram.com/relaxifyapp/?igshid=YmMyMTA2M2Y%3D'
      },

      // {
      //     title: 'Tiktok',
      //     path: require('../public/icons/social/tiktok.svg'),
      //     href: 'https://www.tiktok.com/@relaxifyapp'
      // },
    ],
    socialIconsColored: [
      {
        title: 'Facebook',
        path: require('../public/icons/social-colored/facebook.svg'),
        href: 'https://www.facebook.com/relaxifyap/'
      },
      {
        title: 'LinkedIn',
        path: require('../public/icons/social-colored/linkedin.svg'),
        href: 'https://www.linkedin.com/company/relaxifyapp'
      },
      {
        title: 'Instagram',
        path: require('../public/icons/social-colored/instagram.svg'),
        href: 'https://www.instagram.com/relaxifyapp/?igshid=YmMyMTA2M2Y%3D'
      }
    ]
  },
  {
    page: {
      name: 'corporate',
      parts: [
        {
          partName: 'start page',
          title: 'Invest in the Future With RelaxifyApp',
          description: 'Did you know that nearly a billion people - including 14% of the world’s adolescents were facing mental health issues as of 2019? The UN has already warned that after the sequence of global turmoils, a global mental health crisis is logical and expected.',
          desktop_description: 'Did you know that nearly a billion people - including 14% of the world’s adolescents were facing mental health issues as of 2019? The UN has already warned that after the sequence of global turmoils, a global mental health crisis is logical and expected.\n' +
            'What we strive for is positively impacting this negative tendency by designing a solution instead of replicating one. Thus, we rely solely on fact-based science and innovative technology as means to reach a massive abundance of people and make a tangible impact on their mental state.\n' +
            '<br> The result is called RelaxifyApp - a science-based application for mental fitness and well-being. And it’s something worth investing in.</br>',
          buttonTitle: 'Get in Touch',
          test: '<b> test </b>',
          image: require('../public/corporate/start-page/background.png')
        },
        {
          partName: 'FACTS AND STATS',
          title: 'Mental Health Niche Facts and Stats',
          description: 'RelaxifyApp is not about inspirational quotes. It\'s about the fundamentals of cognitive-behavioral therapy, gestalt psychotherapy, and mindfulness-based interventions, designed as an engaging and smoothly running gameful experience.',
          secondDescription: 'Our recent launch has garnered thousands of paid users, and as we continue to grow, we are searching for the right partners to help us expand our reach and assist even more people in finding peace of mind.',
          buttonTitle: 'Contact sales',
          elements: [
            {
              price: '$242B',
              description: 'The Total Addressable Market (TAM) of the Global Mental Health and Behavioral market by 2027 is $242B.'
            },
            {
              price: '$3.1B',
              description: 'RelaxifyApp is focused on the Mental Health Mobile App market of $3.1B.'
            },
            {
              price: '$250Mil',
              description: 'Our projected Revenue is $250Mil with only a 1% market share.'
            },
          ]
        },
        {
          partName: 'NUMBERS',
          title: 'And Let Numbers Paint the Picture',
          description: 'We believe that measurable results speak louder than words, so here is what we’ve managed to achieve until now',
          elements: [
            {
              image: require('../public/corporate/numbers/user-trusted.svg'),
              count: '25,000',
              description: 'users trusted the RelaxifyApp in one year'
            },
            {
              image: require('../public/corporate/numbers/survey.svg'),
              count: '95,000',
              description: 'survey analyses were completed in one year'
            },
            {
              image: require('../public/corporate/numbers/improvement.svg'),
              count: '55%',
              description: 'improved their well-being after six weeks'
            },
            {
              image: require('../public/corporate/numbers/stars.svg'),
              count: '4.4',
              description: 'stars average rating on the Apple App and Play Stores'
            }
          ],
          buttonTitle: 'Try for Free'
        },
        {
          partName: 'SUCCESS',
          title: 'Our Achievements So Far',
          yellow_dot_icon: require('../public/corporate/success/yellow-dot.svg'),
          achievementsElements: [
            {
              title: 'Google Advisor Program',
              description: 'Our founding team was part of the Google Advisor Program',
              image: require('../public/corporate/success/google-for-startups.png')
            },
            // {
            //     title: 'Android Fitness Magazine',
            //     description: 'Our founding team was part of the Google Advisor Program',
            //     image: ''
            //
            // },
            {
              title: 'Impact Hub Pitch Competition',
              description: 'We have won the Impact Hub Pitch Competition',
              image: require('../public/corporate/success/LOGO-IMPACTHUB.png')
            }
          ]
        },
        {
          partName: 'GROWTH',
          title: 'Our Current Investors',
          description: 'List of current investors that believe in our idea and support our mutual growth.',
          investorsLogos: [
            {
              name: 'Novator',
              logo: require('../public/corporate/investors-logos/novator.svg')
            },
            {
              name: 'Vitosha Venture',
              logo: require('../public/corporate/investors-logos/vitosha-venture.svg')
            },
            {
              name: 'Innovation Capital',
              logo: require('../public/corporate/investors-logos/innovation-capital.svg')
            },
            {
              name: 'GK Global',
              logo: require('../public/corporate/investors-logos/gk-global.svg')
            },
            // {
            //     name: 'Neveq',
            //     logo: require('../public/corporate/investors-logos/neveq.png')
            // }
            {
              name: 'NV3',
              logo: require('../public/corporate/investors-logos/nv3-logo.png')
            },

          ],
          euInstitutionsLogos: [
            {
              name: 'ERDF',
              logo: require('../public/corporate/investors-logos/ERDF.jpg')
            },
            {
              name: 'FOF',
              logo: require('../public/corporate/investors-logos/FOF.png')
            },
            {
              name: 'FOF',
              logo: require('../public/corporate/investors-logos/logo-en-rightOPIC.jpg')
            }
          ],
          descriptions: [
            'An investor in Relaxify Ltd is New Vision 3 Fund, which is co-financed by the European Structural and Investment Funds under the Operational Programme for Innovation and Competitiveness 2014-2020 managed by the Fund of Funds in Bulgaria',
            'Relaxify has been supported by "Vitosha Venture Partners Fund l", a private equity fund, co-financed by the European Structural and Investment Funds under the operational program “Innovation and Competitiveness 2014-2020”, managed by the Fund Manager of Financial Instruments in Bulgaria.',
            'Relaxify has been supported by "Innovation Capital Fund KDA", a private equity fund, co-financed by the European Structural and Investment Funds under the operational program “Innovation and Competitiveness 2014-2020”, managed by the Fund Manager of Financial Instruments in Bulgaria.'
          ]
        }
      ]
    },
  },
  {
    page: {
      name: 'join-the-community',
      parts: [
        {
          partName: 'start page',
          title: 'Become an Advisor at RelaxifyApp',
          description: 'Thank you for your interest in becoming an Advisor at RelaxifyApp!',
          second_description: 'To successfully complete your submission, please fill in the form below and press “apply”. Our representative will reach out to you within the next several days.',
          form: {
            firstName: 'First name*',
            lastName: 'Last name*',
            email: 'Email*',
            message: 'Message*',
            linkedin: 'LinkedIn Profile URL*',
            educationField: 'Educational Field (e.g. Clinical Psychology)*'
          },
          educationalLevelOptions: {
            options: [
              {
                name: 'Bachelor',
                value: 'bachelor',
                id: 1
              },
              {
                name: 'PhD',
                id: 2,
                value: 'phd',
              },
              {
                name: 'Masters',
                id: 3,
                value: 'masters',
              },
              {
                name: 'Others',
                value: 'others',
                id: 4
              }
            ],
            name: 'Educational Level*'
          },
          advisoryAreaOptions: {
            options: [
              {
                name: 'Psychology',
                value: 'psychology'
              },
              {
                name: 'Psychotherapy',
                value: 'psychotherapy'
              },
              {
                name: 'Well-being',
                value: 'well-being'
              }
            ],
            name: 'Advisory Area*'
          },
          formButtonTitle: 'Apply'
        },
      ]
    }
  },
  {
    page: {
      name: 'awards',
      parts: [
        {
          partName: 'WORK-LIFE BALANCE AWARDS',
          title: [
            {text: 'WORK', color: '#193566'},
            {text: '-', color: '#193566'},
            {text: 'LIFE', color: '#FFC726'},
            {text: 'BALANCE', color: '#193566'},
            {text: 'AWARDS', color: '#FFC726'}
          ]
        },
        {
          partName: 'secondPart',
          firstLineTitle: 'Щастието на вашите служители е най-ценното отличие',
          secondLineTitle: 'Нека се допитаме до тях Заедно...',
          mainImage: {
            name: 'Main image',
            image: require('../public/awards/awards-one.png')
          },
          logos: [
            {
              name: 'Relaxify logo',
              image: require('../public/awards/relaxify_-_logo 1.svg')
            },
            {
              name: 'Pleggi logo',
              image: require('../public/awards/Logo Green 1.svg')
            },
            {
              name: 'DiriGo logo',
              image: require('../public/awards/Asset 1 1.svg')
            }
          ]
        },
        {
          partName: 'thirdPart',
          yellowRectangle: {
            title: 'В кои компании балансът между работа и личен живот е най-добър според служителите',
            desc: 'Все повече фирми привличат таланти обещавайки им Work-Life Balance, но в колко компании това е доказано чрез обратната връзка от служителите?',
          },
          greyAwards: {
            title: 'Work-life Balance Awards Ви носи:',
            elements: [
              {
                title: 'Рейтинг, анализ на данни и препоръки',
                desc: 'Вашите служители ще попълнят професионален психологически въпросник, който е подкрепен от научни изследвания. Резултатите ще бъдат анализирани от експерт психолози и предоставени на вас под формата на кратък анализ.',
                logo: {
                  name: 'Rating',
                  image: require('../public/awards/greyAwards/rating-chart.svg')
                },
              },
              {
                title: 'Employer branding',
                desc: 'Интервю, статия, видео материали и специален бадж, които да използвате',
                logo: {
                  name: 'Branding',
                  image: require('../public/awards/greyAwards/bradning.svg')
                },
              },
              {
                title: 'Събитие, награди и сертификати',
                desc: 'За Топ компаниите в различни индустрии',
                logo: {
                  name: 'Event',
                  image: require('../public/awards/greyAwards/event.svg')
                },
              },
              {
                title: 'Mental Health App',
                desc: 'Premium достъп до mental health app, за всеки служител, попълнил въпросника за анализ',
                logo: {
                  name: 'Mental health',
                  image: require('../public/awards/greyAwards/rating-chart.svg')
                },
              }
            ],
            phones: {
              name: 'phones-image',
              image: require('../public/awards/greyAwards/phones.svg')

            },
            phonesDesktop: {
              name: 'phones-image-for-desktop',
              image: require('../public/awards/greyAwards/phones-desktop.svg')

            },
            button: {
              title: 'Свържи се с нас, за да участваш'
            },
            lastElement: {
              title: 'Участието в Work-life Balance Awards ви дава възможност да съберете анонимна обратна връзка от служителите си.  Рейтингите на компаниите ще се публикуват само с ваше съгласие. Ние ще наградим тези, които са над определен и здравословен бенчмарк.'
            }
          }
        },
        {
          partName: 'fourthPart',
          firstIcon: {
            name: 'Pen',
            image: require('../public/awards/pen.svg')
          },
          title: 'Какво измерваме?',
          measuresFirstTitle: 'Business Metrics:',
          measuresSecondTitle: 'Mental Health Metrics:',
          measures: [
            {
              title: 'Employee Engagement',
              desc: 'Открийте какво мотивира вашите служители и засилва тяхната ангажираност.',
              logo: {
                name: 'Employee Engagement',
                image: require('../public/awards/measureElements/employee-engagement.svg')
              }
            },
            {
              title: 'Employee Productivity',
              desc: 'Разберете какво спира продуктивността, за да адресирате областите за подобрение.',
              logo: {
                name: 'Employee Engagement',
                image: require('../public/awards/measureElements/employee-productivity.svg')
              }
            },
            {
              title: 'Job Satisfaction',
              desc: 'Измерете удовлетворението на вашите служители от техните роли и взаимоотношения на работното място.',
              logo: {
                name: 'Job Satisfaction',
                image: require('../public/awards/measureElements/job-satisfaction.svg')
              }
            },
            {
              title: 'Stress Levels',
              desc: 'Измерете средната честота и интензивност на стреса, който изпитват вашите служители.',
              logo: {
                name: 'Stress Levels',
                image: require('../public/awards/measureElements/stress-levels.svg')
              }
            },
            {
              title: 'Burnout Indicators',
              desc: 'Октрийте признаци на прегаряне, за да предотвратите проблеми с ефективността и благосъстоянието на вашия екип.',
              logo: {
                name: 'Burnout Indicators',
                image: require('../public/awards/measureElements/burnout-indicators.svg')
              }
            },
            {
              title: 'Employee Well-being',
              desc: 'Разберете повече за емоционалното, когнитивното, физическото и социалното благополучие на служителите си.',
              logo: {
                name: 'Well-being',
                image: require('../public/awards/measureElements/well-being.svg')
              }
            }
          ],
          markImage: {
            name: 'Mark image',
            image: require('../public/awards/measureElements/mark.png')
          }
        },
        {
          partName: 'fifthPart',
          title: 'Какъв е процесът?',
          processItems: [
            {
              title: 'Въпросник',
              desc: 'Вашите служители ще попълнят анонимен психологически въпросник, който е подкрепен от научни изследвания.<br><br>\n' +
                'Може да се попълни през телефон или компютър<br><br>\n' +
                'Този въпросник ще ни помогне да оценим културата и нивата на стрес във вашата компания.\n',
              logo: {
                name: 'phone-process',
                image: require('../public/awards/processItems/phone.svg')
              }
            },
            {
              title: 'Изследване и  рейтинг',
              desc: 'Вашите резултати ще бъдат сравнени с бенчмарк и ще получите определен рейтинг.<br><br>\n' +
                'Топ компаниите ще бъдат наградени.<br><br>\n' +
                'За да сме ви максимално полезни, всички компании получават анализ на резултатите си от организационни психолози.\n',
              logo: {
                name: 'brain-process',
                image: require('../public/awards/processItems/brain.svg')
              }
            },
            {
              title: 'Награждаване',
              desc: 'Награждаване на събитието на Work-life Balance Awards<br><br>\n' +
                'Медийно покритие в партньорски организации<br><br>\n' +
                'Work-life Balance badge - Вашият badge може да се използва във всички ваши маркетингови материали. Хората заслужават да знаят, че сте страхотен работодател.\n',
              logo: {
                name: 'medal-process',
                image: require('../public/awards/processItems/medal.svg')
              }
            },
          ]

        },
        {
          partName: 'sixthPart',
          greyCompanies: [
            '• Телекомуникации', '• Фармация', '• Производство', '• Ресторанти/Хотели', '• НПО'
          ],
          blueCompanies: [
            '• Банки, финанси', '• Счетоводство и одит', '• HR агенции', '• Продажби на стоки', '• IT'
          ],
          title: 'В класацията участват над 50 компании от различни сектори:'
        },
        {
          partName: 'lastElement',
          title: 'ЦЕНА ЗА УЧАСТИЕ',
          price: '2500 лв',
          until: 'Заяви участие до 30.11.2024 ',
          desc: 'Резервирайте участието си сега, като се свържете с нас',
          buttonTitle: 'Свържете с нас',
          logo: {
            name: 'worker',
            image: require('../public/awards/worker.svg')
          }
        }
      ]
    }
  }
]
export default resources;
